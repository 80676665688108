import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useRoster, useRosterDetails, useRosters } from '../hooks/use-rosters';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { usePageTitle } from '../hooks/use-page-title';

export function RosterPage() {
	const { rosterSlug } = useParams();
	const { roster, error: rosterError } = useRoster(rosterSlug);
	const { rosterDetails, error: memberError } = useRosterDetails(roster?.id);

	usePageTitle(roster?.name);

	const navigate = useNavigate();
	const { rosters } = useRosters();
	useEffect(() => {
		if (!rosterSlug && rosters && rosters.length > 0) {
			navigate(`/rosters/${rosters[0].slug}`, { replace: true });
		}
	}, [navigate, rosterSlug, rosters]);

	if (rosterError) {
		return <div>There was an error: {rosterError.message}</div>;
	}

	if (rosters?.length === 0) {
		return (<div>There are no associated rosters.</div>);
	}

	if (!roster || !rosterDetails) {
		return (<div>Loading...</div>);
	}

	return (<div className="content-area">
		<h1>{roster.name}</h1>
		<div className="row row--tables">
			<div className="col-12 col-lg-6">
				{/* <h2 className="h4-styling">{roster.name}</h2> */}
				<div className="table-responsive">
					<table className="table table--simple">
						<tbody>
							{rosterDetails.department && <tr>
								<td>Department</td>
								<td>{rosterDetails.department}</td>
							</tr>}
							<tr>
								<td>Coordinator</td>
								<td>{rosterDetails.coordinatorName}</td>
							</tr>
							<tr>
								<td>Parent Committee</td>
								<td>{rosterDetails.parentCommittee}</td>
							</tr>
							<tr>
								<td>Email</td>
								<td>{rosterDetails.emailAddress && <a href={`mailto:${rosterDetails.emailAddress}`}>{rosterDetails.emailAddress}</a>}</td>
							</tr>
							<tr>
								<td>Start Date</td>
								<td>{rosterDetails.startDate?.toLocaleDateString()}</td>
							</tr>
							<tr>
								<td>End Date</td>
								<td>{rosterDetails.endDate?.toLocaleDateString()}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<LoadingOverlay isLoading={!rosterDetails && !memberError}>
			<h2 className="h4-styling">Committee Members</h2>
			<div className="table-responsive">
				<table className="table">
					<thead>
						<tr>
							<th><span className="hidden-for-accessibility">Number</span></th>
							<th>Member</th>
							<th>Company</th>
							<th>Telephone</th>
							<th>Email</th>
							<th>Start Date</th>
							<th>End Date</th>
						</tr>
					</thead>
					<tbody>
						{rosterDetails.members && rosterDetails.members.map((member, index) => (
							<tr key={member.id}>
								<td>{index + 1}</td>
								<td>{member.name}</td>
								<td>{member.company}</td>
								<td>{member.phoneNumber}</td>
								<td>{member.email && <a href={`mailto:${member.email}`}>{member.email}</a>}</td>
								<td>{member.startDate?.toLocaleDateString()}</td>
								<td>{member.endDate?.toLocaleDateString()}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{memberError && (<div>There was an error: {memberError.message}</div>)}
		</LoadingOverlay>
	</div>);
}